var userAgent = window.navigator.userAgent.toLowerCase();
var isIE = false;
if (userAgent.indexOf('msie') != -1 ||
  userAgent.indexOf('trident') != -1) {
  isIE = true;
} else if (userAgent.indexOf('edge') != -1) {} else if (userAgent.indexOf('chrome') != -1) {} else if (userAgent.indexOf('safari') != -1) {} else if (userAgent.indexOf('firefox') != -1) {} else if (userAgent.indexOf('opera') != -1) {} else {}

// HTML-LOAD
$(function() {});

// ASSETS-LOAD
$(window).on('load', function() {

  $(".loading").fadeOut(600, function() {
    $(".container").css("opacity", "1");
    $(".mv_base, .mv_base2").addClass("active");
    scrollAnime();
  });
  $(".mv_image").slick({
    autoplay:true,
    fade: true,
    arrows:false,
    adaptiveHeight:true,
    centerMode:true,
    speed:3000
  });

});

$(window).on("resize", function() {
  scrollAnime();
});

$(window).on("scroll", function() {
  scrollAnime();
});

$('a.scroller[href^="#"]').click(function() {
  var href = $(this).attr("href");
  var target = $(href);
  var position = target.offset().top;
  $('body,html').stop().animate({
    scrollTop: position
  }, 500);
  return false;
});

$(".layout_form_btn").on("click", function(event) {
  var bPost = true;
  $("#name1").val($("#lay_name1").val() + $("#lay_name2").val());
  $("#name2").val($("#lay_kana1").val() + $("#lay_kana2").val());
  /*
  if (!$("#mail").val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
    $(".load_error").html("正しいメールアドレスを入力してください。");
    bPost = false;
  }
  if ($("#mail_conf").val() != $("#mail").val()) {
    $(".load_error").html("入力したメールアドレスが一致しません");
    bPost = false;
  }
*/
  if (bPost) {
    var formData = $("#idForm").serialize();
    $.ajax({
      url: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSd2PlTLogKzwvYFyDDHqj3nwt9jbGSV7lnBoAmDCvUr9hG1QA/formResponse",
      data: formData,
      type: "POST",
      dataType: "xml",
      statusCode: {
        0: function () {
          console.log("送信完了");
          $(".load_message").html("送信完了しました。");
          $(".load_message").fadeIn(300);
        },
        200: function () {
          console.log("ERROR");
          $(".load_message").html("送信できませんでした。");
        },
      },
    });
    $("textarea, :text").val("").end().find(":checked").prop("checked", false);
    event.preventDefault();
    setTimeout(function () {
      $(".load_message").fadeOut(300);
    }, 2000);
  } else {
    $(".layout_form_btn").prop("disabled", true);
  }
});

$(".openShare").on("click", function() {

  let TYPE = $(this).data("type");
  var SHARE_URL = "";
  var SHARE_TITLE = TITLE;

  if ($(this).data("page")) {
    SHARE_URL = URL + $(this).data("page");
    SHARE_TITLE = $("title").first().text();
  }

  // SNS type
  switch (TYPE) {
    case "tw":
      SHARE_URL = "https://twitter.com/intent/tweet?text=" + SHARE_TITLE + "&url=" + URL;
      break;
    case "fb":
      SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u=" + URL;
      break;
    case "line":
      SHARE_URL = "https://social-plugins.line.me/lineit/share?url=" + URL;
      break;
    default:
      break;
  }
  console.log(SHARE_URL)
  window.open(SHARE_URL, TYPE, 'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=1');
});

$("input[type='text'].-required, input[type='radio'].-required, textarea.-required").on("keydown keyup keypress change", function(event) {
  var ready = false;

  // テキスト入力とテキストエリアのチェック
  $("input[type='text'].-required, textarea.-required").each(function(index, dom) {
    if ($(dom).val() == '') {
      ready = true;
      return false; // チェックが完了したらループを抜ける
    }
  });

  // ラジオボタンのチェック
  if (!ready) {
    $("input[type='radio'].-required").each(function(index, dom) {
      var name = $(dom).attr('name');
      if ($(`input[name="${name}"]:checked`).length === 0) {
        ready = true;
        return false; // チェックが完了したらループを抜ける
      }
    });
  }

  $(".layout_form_btn_recruit").prop("disabled", ready);
  $(".layout_form_btn").prop("disabled", ready);
  $(".load_error").html("");
});


function scrollAnime() {
  var windowHeight = $(window).innerHeight(),
    scrollPos = $(document).scrollTop(),
    fadeH = 250;

    // console.log(windowHeight);// 画面高さ
    // console.log(topWindow);// スクロール位置、0始まり
    // console.log(scrollPos); // スクロール位置、0始まり

  $('.fadein').each(function() {
    var targetPosition = $(this).offset().top;
    fadeH = $(this).data("fadeheight") != undefined ? $(this).data("fadeheight") : fadeH;
    if (scrollPos > targetPosition - windowHeight + (fadeH) || $(this).hasClass("firstView")) {
      $(this).addClass($(this).data("fadein"));
    }
  });
  $('.scrollin').each(function() {
    var targetPosition = $(this).offset().top;
    fadeH = $(this).data("fadeheight") != undefined ? $(this).data("fadeheight") : fadeH;

    // 画面内に入ってきたら
    if (scrollPos > (targetPosition - windowHeight + fadeH )) {
      $(this).addClass($(this).data("fadein"));
    }else{
      $(this).removeClass($(this).data("fadein"));
    }
    if (scrollPos > (targetPosition + $(this).innerHeight() - fadeH)) {
      $(this).removeClass($(this).data("fadein"));
    }
  });
}

// $(".layout_form_btn_recruit").on("click", function (event) {
//   var bPost = true;
//   if (bPost) {
//     var formData = $("#recruitForm").serialize();
//     $.ajax({
//       url: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdYrpHoBAc4zfWtBU3ehSkfmXeAQ3iciK2pZr2q4mtUz6Jm_A/formResponse",
//       data: formData,
//       type: "POST",
//       dataType: "xml",
//       statusCode: {
//         0: function () {
//           console.log("送信完了");
//           $(".load_message").html("送信完了しました。");
//           $(".load_message").fadeIn(300);
//         },
//         200: function () {
//           console.log("ERROR");
//           $(".load_message").html("送信できませんでした。");
//         },
//       },
//     });
//     $("textarea, :text").val("").end().find(":checked").prop("checked", false);
//     event.preventDefault();
//     setTimeout(function () {
//       $(".load_message").fadeOut(300);
//     }, 2000);
//   } else {
//     $(".layout_form_btn_recruit").prop("disabled", true);
//   }
// });

$(".layout_form_btn_recruit").on("click", function (event) {
  var bPost = true;
  if (bPost) {
    var formData = $("#recruitForm").serialize();
    $.ajax({
      url: "https://script.google.com/macros/s/AKfycbyTK2NBXvZESMRx2enUcjQvgXGnKIkSDxQLGaOVGKMgsTwTobApedTc0_0ku3CsgqM/exec", // ここを変更
      data: formData,
      type: "POST",
      dataType: "xml",
      statusCode: {
        0: function () {
          console.log("送信完了");
          $(".load_message").html("送信完了しました。");
          $(".load_message").fadeIn(300);
        },
        200: function () {
          console.log("ERROR");
          $(".load_message").html("送信できませんでした。");
        },
      },
    });
    $("textarea, :text").val("").end().find(":checked").prop("checked", false);
    event.preventDefault();
    setTimeout(function () {
      $(".load_message").fadeOut(300);
    }, 2000);
  } else {
    $(".layout_form_btn_recruit").prop("disabled", true);
  }
});